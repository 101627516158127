import React from 'react';
import { graphql } from 'gatsby';
import css from 'src/theme/css';

import Article from './Article';
import Event from './Event';
import RichText from 'src/components/RichText';
import SmartButton from 'src/components/SmartButton';
import ModuleWrapper from 'src/components/ModuleWrapper';
import { Flex, Box } from 'src/components/FlexBox';

import { useResponsiveValue } from 'src/components/Breakpoints';

const componentLookup = {
  ContentfulArticle: Article,
  ContentfulEvent: Event,
};

export default ({
  text,
  primaryArticle,
  secondaryArticles,
  buttons,
  shouldCollapseMargin,
  colorMode,
  ...other
}) => {
  const primaryLayout = useResponsiveValue([
    'vertical',
    'horizontal',
    null,
    'compact',
  ]);

  const secondaryLayout = useResponsiveValue(['vertical', 'horizontal']);

  const renderedPrimaryItem = (() => {
    const Component = componentLookup[primaryArticle.__typename];
    return <Component {...primaryArticle} layout={primaryLayout} />;
  })();

  const renderedSecondaryItems = secondaryArticles.map((article) => {
    const Component = componentLookup[article.__typename];
    return <Component key={article.id} {...article} layout={secondaryLayout} />;
  });

  return (
    <ModuleWrapper
      {...other}
      colorMode={colorMode || 'light'}
      flexDirection="column"
    >
      <Flex
        gy={5}
        flexDirection="column"
        css={css({
          px: 'pageMargin',
          pt: shouldCollapseMargin ? 0 : 'sectionMargin',
          pb: 'sectionMargin',
        })}
      >
        {text && (
          <RichText size="m" alignItems="center" textAlign="center" {...text} />
        )}
        <Flex
          gx={5}
          gy={4}
          flexDirection={['column', null, null, 'row']}
          justifyContent="stretch"
          css={css({ flexBasis: '100%' })}
        >
          <Box flexDirection="column" flexBasis="100%" justifyContent="center">
            {renderedPrimaryItem}
          </Box>
          <Flex
            gy={[4, null, null, 5]}
            flexDirection="column"
            justifyContent="center"
            flexBasis="100%"
          >
            {renderedSecondaryItems}
          </Flex>
        </Flex>
        {buttons instanceof Array && (
          <Flex gx={5} gy={5} justifyContent="center">
            {buttons.map((button) => (
              <SmartButton key={button.id} {...button} />
            ))}
          </Flex>
        )}
      </Flex>
    </ModuleWrapper>
  );
};

export const query = graphql`
  fragment FeaturedArticlesModuleFragment on ContentfulFeaturedArticlesModule {
    id
    colorMode
    text {
      json
    }
    primaryArticle {
      ... on ContentfulArticle {
        ...FeaturedArticlesModuleArticleFragment
      }
      ... on ContentfulEvent {
        ...FeaturedArticlesModuleEventFragment
      }
    }
    secondaryArticles {
      ... on ContentfulArticle {
        ...FeaturedArticlesModuleArticleFragment
      }
      ... on ContentfulEvent {
        ...FeaturedArticlesModuleEventFragment
      }
    }
    buttons {
      ...ButtonFragment
    }
    shouldCollapseMargin
  }
`;
