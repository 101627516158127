import React from 'react';
import BackgroundImg from 'gatsby-background-image';
import styled from 'styled-components';
import css from 'src/theme/css';
import { graphql } from 'gatsby';
import get from 'lodash/get';

import ColorProvider from 'src/components/ColorProvider';
import Frame from 'src/components/Frame';
import IconPreset from 'src/components/IconPreset';
import { Flex, Box } from 'src/components/FlexBox';
import CircleIcon, * as circleIcon from 'src/components/CircleIcon';
import SmartButton, * as smartButton from 'src/components/SmartButton';
import Text from 'src/components/Text';

export const layouts = {
  compact: 'compact',
  horizontal: 'horizontal',
  vertical: 'vertical',
};

const CardImg = styled(BackgroundImg)(
  css({
    borderRadius: 2,
    overflow: 'hidden',
    p: 4,
    pt: [3, 4],
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridTemplateRows: 'auto auto',
    gridTemplateAreas: `'logo logo' 'title icon'`,
    gridGap: 3,
    alignContent: 'space-between',
    transition: 'transform 1.2s cubic-bezier(0.19, 1, 0.22, 1)',
    transform: 'scale(1)',
    boxShadow: 'shadow.m',
    color: 'mode',
    '::before, ::after': {
      transform: 'scale(1.1)',
      transition: 'transform 1.2s cubic-bezier(0.19, 1, 0.22, 1) !important;',
    },
  })
);

export default ({ title, layout, slug, logo, previewImage, ...other }) => {
  const previewImageStack = [
    layout === layouts.horizontal
      ? 'linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))'
      : 'linear-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 50%, rgb(0, 0, 0) 100%)',
    previewImage.fluid,
  ];

  const compact = (
    <ColorProvider mode="dark">
      <Frame ratio={3 / 4} css={{ flexGrow: 1 }}>
        <CardImg fluid={previewImageStack} style={{ position: 'absolute' }}>
          <Text variant="heading.m">{title}</Text>
          <IconPreset
            {...logo}
            css={{ gridArea: 'logo', justifySelf: 'end' }}
          />
          <CircleIcon
            type="arrow"
            css={{
              gridArea: 'icon',
              alignSelf: 'end',
              justifySelf: 'end',
            }}
          />
        </CardImg>
      </Frame>
    </ColorProvider>
  );

  const horizontal = (
    <Flex gx={4} justifyContent="stretch" alignItems="center">
      <Flex gy={3} flexDirection="column" alignItems="start" flex="1 1 100%">
        <Text variant="heading.m">{title}</Text>
        <SmartButton label="Learn More" variant={smartButton.variants.arrow} />
      </Flex>
      <Box flex="1 1 100%">
        <ColorProvider mode="dark">
          <Frame ratio={2 / 3} css={{ flexGrow: 1 }}>
            <CardImg
              fluid={previewImageStack}
              style={{ position: 'absolute' }}
            />
          </Frame>
        </ColorProvider>
      </Box>
    </Flex>
  );

  const vertical = (
    <Flex gy={4} flexDirection="column" justifyContent="stretch">
      <Box flex="1 1 100%">
        <ColorProvider mode="dark">
          <Frame ratio={2 / 3} css={{ flexGrow: 1 }}>
            <CardImg fluid={previewImageStack} style={{ position: 'absolute' }}>
              <IconPreset
                {...logo}
                css={{ gridArea: 'logo', justifySelf: 'end' }}
              />
              <CircleIcon
                type="arrow"
                css={{
                  gridArea: 'icon',
                  alignSelf: 'end',
                  justifySelf: 'end',
                }}
              />
            </CardImg>
          </Frame>
        </ColorProvider>
      </Box>
      <Flex gy={3} alignItems="start" flexDirection="column" flex="1 1 100%">
        <Text variant="heading.m">{title}</Text>
        <SmartButton label="Learn More" variant={smartButton.variants.arrow} />
      </Flex>
    </Flex>
  );

  const lookup = {
    [layouts.compact]: compact,
    [layouts.horizontal]: horizontal,
    [layouts.vertical]: vertical,
  };

  return (
    <SmartButton
      action="Link"
      url={`/${slug}/`}
      {...other}
      css={css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        ':hover': {
          ...circleIcon.hoverStyles,
          [CardImg]: {
            transform: 'scale(1.05)',
            '::before, ::after': {
              transform: 'scale(1)',
            },
          },
        },
      })}
    >
      {get(lookup, layout, layouts.compact)}
    </SmartButton>
  );
};

export const query = graphql`
  fragment FeaturedArticlesModuleEventFragment on ContentfulEvent {
    id
    title
    slug
    previewImage {
      fluid(maxWidth: 1000, quality: 80) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    logo {
      identifier
      media {
        file {
          url
        }
      }
    }
  }
`;
